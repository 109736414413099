@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"),
    url("../assets/fonts/opensans.ttf") format("truetype");
}
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  src: local("Oswald"), local("Oswald"),
    url("../assets/fonts/oswald.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto"),
    url("../assets/fonts/roboto.ttf") format("truetype");
}
body {
  margin: 0;
  text-align: center;
  font-family: "Open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0%;
}

section {
  padding: 0 10%;
  overflow: hidden;
}
@media (max-width: 768px) {
  section {
    padding: 0 5%;
    overflow: hidden;
  }
}
.yellow {
  background: #ffe061;
}
.subscribeP {
  padding: 0;
}
.border-top {
  border-top: 3px solid #000;
  border-bottom: 3px solid #000;
}
.grey {
  background: #f8f8f8;
}
.red {
  background: #fa4443;
}
@media (max-width: 768px) {
  .m-Dnone {
    background: none;
    border: none;
  }
}
.space {
  height: 70px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.react-tagsinput-input {
  min-width: 100%;
}
.react-tagsinput > span {
  min-width: 100%;
}
